






















































import Vue from 'vue';
import { HomeIcon, UserIcon } from 'vue-feather-icons';

export default Vue.extend({
  name: 'Home',
  components: { UserIcon, HomeIcon }
});

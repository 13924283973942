
































































































import Vue from 'vue';
import { ClassDetails, classes } from '@/data/classes';
import { ChevronLeftIcon, HomeIcon } from 'vue-feather-icons';

export default Vue.extend({
  name: 'ClassDetails',
  data: () => ({
    classDetails: undefined as undefined | ClassDetails
  }),
  mounted() {
    const slugNeedle = this.$router.currentRoute.path.replace(/^.*\/(.*?)$/, '$1');
    this.classDetails = classes.find(({ slug }) => slug === slugNeedle);
  },
  components: { ChevronLeftIcon, HomeIcon }
});

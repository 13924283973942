






























































import Vue from 'vue';
import { HelpCircleIcon, HomeIcon } from 'vue-feather-icons';

export default Vue.extend({
  name: 'FAQ',
  components: { HelpCircleIcon, HomeIcon }
});





























import Vue, { PropType } from 'vue';

interface Image {
  url: string;
  width: number;
}

export default Vue.component('ImageFlow', {
  props: {
    images: {
      type: Array as PropType<Array<Image>>,
      required: true
    },
    intervalTimeout: {
      type: Number,
      required: false
    },
    speed: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    shuffledImages: [] as Array<Image>,
    intervalId: undefined as number | undefined,
    offset: 0,
    paused: false
  }),
  mounted() {
    this.shuffledImages = [...this.$props.images];
    this.shuffledImages.sort(() => Math.abs(Math.random() * 2) || -1); // Shuffle
    const handler = () => {
      if (this.paused) return;
      const cutOverPosition = this.$el?.firstElementChild?.firstElementChild?.scrollWidth || Number.POSITIVE_INFINITY;
      this.offset += (this.$props.speed || 1);
      if (this.offset > cutOverPosition) {
        this.offset -= cutOverPosition;
        this.shuffledImages.push(this.shuffledImages.shift() as Image);
      }
    };
    this.intervalId = setInterval(handler, this.$props.intervalTimeout || 10);
  },
  destroyed() {
    clearInterval(this.intervalId);
    this.intervalId = undefined;
  },
  methods: {
    pause() {
      this.paused = true;
    },
    unpause() {
      this.paused = false;
    }
  }
});


























import Vue from 'vue';
import ImageFlow from '@/components/ImageFlow.vue';

export default Vue.extend({
  name: 'Home',
  components: { ImageFlow },
  data: () => ({
    imageFlowImages: [
      {
        url: require('../assets/photos/home/20200620_151541.jpg'),
        width: 320,
        altText: 'Jehtro presenting Amelie standing on the wheel'
      },
      {
        url: require('../assets/photos/home/20201202_153700.jpg'),
        width: 320,
        altText: 'Rocking on the wheel at Carmel School'
      },
      {
        url: require('../assets/photos/home/carmel-2020.jpg'),
        width: 427,
        altText: 'Carmel School group 2020 group photo'
      },
      {
        url: require('../assets/photos/home/IMG-20200910-WA0000.jpg'),
        width: 248,
        altText: 'Emma and Lilach in performance'
      },
      {
        url: require('../assets/photos/home/IMG-20201202-WA0010.jpg'),
        width: 108,
        altText: 'Toby standing on the wheel'
      },
      {
        url: require('../assets/photos/home/20200620_151541.jpg'),
        width: 320,
        altText: 'Jehtro presenting Amelie standing on the wheel'
      },
      {
        url: require('../assets/photos/home/20201202_153700.jpg'),
        width: 320,
        altText: 'Rocking on the wheel at Carmel School'
      },
      {
        url: require('../assets/photos/home/carmel-2020.jpg'),
        width: 427,
        altText: 'Carmel School group 2020 group photo'
      },
      {
        url: require('../assets/photos/home/IMG-20200910-WA0000.jpg'),
        width: 248,
        altText: 'Emma and Lilach in performance'
      },
      {
        url: require('../assets/photos/home/IMG-20201202-WA0010.jpg'),
        width: 108,
        altText: 'Toby standing on the wheel'
      }
    ]
  })
});

import VueRouter from 'vue-router';
import Vue from 'vue';
import { routes } from '@/router/routes';

const siteSuffix = ['Perth Wheel', 'Western Australia'];
const titleDivider = ' | ';

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.afterEach(({ meta, path }) => Vue.nextTick(() => {
  const title = meta && meta.title
    ? [typeof meta.title === 'function' ? meta.title(path) : meta.title]
    : [];
  document.title = [...title, ...siteSuffix].join(titleDivider);
}));

export default router;

/* eslint-disable max-len */

export interface ClassDetails {
  slug: string;
  archive?: boolean;
  heading?: string;
  logo?: string;
  intro?: string;
  description?: string;
  description2?: string;
  startDate?: Date;
  endDate?: Date;
  dateComments?: string;
  locationUrl?: string;
  locationName?: string;
  locationAddress?: string;
  howMuch?: string;
  who?: string;
  externalBookingUrl?: string;
  externalBookingText?: string;
}

export const classes: Array<ClassDetails> = [
  {
    slug: 'wheel-at-carmel-2021',
    heading: 'Wheel @ Carmel',
    logo: require('../assets/partner-logos/carmel-school-logo.png'),
    intro: 'Perth Wheel runs weekly classes during school terms at Carmel School.',
    description: 'Starting just after school on Wednesdays the classes are perfect for students attending Carmel School in Dianella or other nearby schools.',
    startDate: new Date('2021-02-24'),
    endDate: new Date('2021-04-07'),
    locationUrl: 'https://www.carmel.wa.edu.au/',
    locationName: 'Carmel School',
    locationAddress: 'Dianella Western Australia',
    howMuch: '$20 per class',
    who: 'Class is open to Carmel students and other high school-aged students who can attend. Minimum 4 students required for class to go ahead.'
  },
  {
    slug: 'wheel-at-cirquest',
    heading: 'Wheel @ CirQuest',
    logo: require('../assets/partner-logos/cirquest-logo.png'),
    intro: 'Perth Wheel runs weekly classes in partnership with CirQuest Circus school.',
    description: 'Classes are for adults and mature teenagers and must be booked through CirQuest’s online booking system (see link below).  While you are there, check out their other great classes.',
    locationUrl: 'https://www.cirquest.com.au/adults',
    locationName: 'CirQuest Circus School',
    locationAddress: 'North Perth Western Australia',
    howMuch: '$40 casual. 10% discount for booking more than one class or for more than one family member. Membership discounts also available.',
    who: 'Adults and mature teenagers.',
    externalBookingUrl: 'https://www.cirquest.com.au/adults',
    externalBookingText: 'Please enrol with CirQuest via their online booking system'
  },
  {
    slug: 'wheel-at-carmel-2020',
    archive: true,
    heading: 'Wheel @ Carmel 2020',
    logo: require('../assets/partner-logos/carmel-school-logo.png'),
    description: 'A super fun class where you get to learn cool wheel tricks.  Wheel is also good for your core strength, balance and flexibility.  This class is suitable for teenagers.',
    description2: 'The very reasonable fee of $20 per class must be paid before classes commence.  Payment details are provided when you enrol.',
    startDate: new Date('2020-11-18'),
    endDate: new Date('2020-12-02'),
    dateComments: `${new Date('2020-12-02').toLocaleDateString()} performance at end of class`,
    locationUrl: 'https://www.carmel.wa.edu.au/',
    locationName: 'Carmel School',
    locationAddress: 'Dianella Western Australia',
    howMuch: '$20 per class or $50 for all three',
    who: 'Class is open to Carmel students and other high school-aged students who can attend. Minimum 4 students required for class to go ahead.'
  }
];



































import Vue from 'vue';
import { FacebookIcon, InstagramIcon, MailIcon } from 'vue-feather-icons';

export default Vue.component('Footer', {
  components: { FacebookIcon, InstagramIcon, MailIcon }
});
































































































import Vue from 'vue';
import { HomeIcon, PackageIcon } from 'vue-feather-icons';

export default Vue.extend({
  name: 'Packages',
  components: { PackageIcon, HomeIcon }
});


























import Vue from 'vue';
import { HomeIcon } from 'vue-feather-icons';
import ContactForm from '@/components/ContactForm.vue';

export default Vue.extend({
  name: 'Contact',
  components: { ContactForm, HomeIcon }
});

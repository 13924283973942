




































import Vue from 'vue';
import { MailIcon } from 'vue-feather-icons';

export default Vue.component('ContactForm', {
  data: () => ({
    submitUrl: '',
    formData: {
      source: '',
      fullName: '',
      email: '',
      message: '',
      captchaResponse: ''
    }
  }),
  methods: {
    submit(evt: Event) {
      // TODO AJAX POST JSON
      console.log(JSON.stringify(this.formData));
      evt.preventDefault();
    }
  },
  components: { MailIcon }
});

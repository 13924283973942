






























































import Vue from 'vue';
import {
  AlertTriangleIcon,
  BellIcon,
  GlobeIcon,
  HelpCircleIcon,
  HomeIcon,
  MailIcon,
  PackageIcon,
  UserIcon
} from 'vue-feather-icons';

export default Vue.component('Navigation', {
  components: { AlertTriangleIcon, HomeIcon, GlobeIcon, UserIcon, BellIcon, PackageIcon, HelpCircleIcon, MailIcon }
});











































import Vue from 'vue';
import { AlertTriangleIcon } from 'vue-feather-icons';

export default Vue.extend({
  name: 'Covid19',
  components: { AlertTriangleIcon }
});

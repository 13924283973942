import { RouteConfig } from 'vue-router';
import Home from '@/views/Home.vue';
import WhatIsWheel from '@/views/WhatIsWheel.vue';
import Classes from '@/views/Classes.vue';
import ClassDetails from '@/views/ClassDetails.vue';
import Packages from '@/views/Packages.vue';
import Trainers from '@/views/Trainers.vue';
import FAQ from '@/views/FAQ.vue';
import Contact from '@/views/Contact.vue';
import Covid19 from '@/views/Covid19.vue';
import NotFound from '@/views/NotFound.vue';
import { classes } from '@/data/classes';

export const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/what-is-wheel',
    name: 'What is Wheel?',
    meta: {
      title: 'What is Wheel?'
    },
    component: WhatIsWheel
  },
  {
    path: '/trainers',
    name: 'Trainers',
    meta: {
      title: 'Meet Emma and the other trainers'
    },
    component: Trainers
  },
  {
    path: '/classes',
    name: 'Classes',
    meta: {
      title: 'Gymnastic Wheel Classes'
    },
    component: Classes
  },
  {
    path: '/classes/:slug',
    name: 'Class Details',
    meta: {
      title: (path: string) => {
        const match = classes.find(({ slug }) => path === `/classes/${slug}`);
        return `${match ? `${match.heading} | ` : ''}Gymnastic Wheel Classes`;
      }
    },
    component: ClassDetails
  },
  {
    path: '/packages',
    name: 'Packages',
    meta: {
      title: 'Custom packages for your school or business'
    },
    component: Packages
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: {
      title: 'Frequently Asked Questions'
    },
    component: FAQ
  },
  {
    path: '/contact',
    name: 'Contact',
    meta: {
      title: 'Contact us'
    },
    component: Contact
  },
  {
    path: '/covid-19',
    name: 'COVID-19 Information',
    meta: {
      title: 'COVID-19 information'
    },
    component: Covid19
  },
  {
    path: '*',
    name: '404 Not Found',
    meta: {
      title: '404 Not Found'
    },
    component: NotFound
  }
];
